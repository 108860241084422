import { FaCheck, FaPencilAlt } from "react-icons/fa";
import { classNames } from "../lib/utils";

const CircleIcon = ({ children, className, style }) => (
    <div className={classNames(["circleIcon", className])} style={style}>
        {children}
    </div>
);

export const DirtyIndicator = ({ isDirty }) => {
    return isDirty === undefined ? null : (
        <div className={classNames(["dirtyIndicator", isDirty ? "unsaved" : "saved"])}>
            {isDirty ? (
                <span>
                    Unsaved changes
                    <CircleIcon className="unsaved">
                        <FaPencilAlt size="0.8em" />
                    </CircleIcon>
                </span>
            ) : (
                <span>
                    Saved
                    <CircleIcon className="saved">
                        <FaCheck size="0.8em" />
                    </CircleIcon>
                </span>
            )}
        </div>
    );
};
