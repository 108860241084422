import User from "./User";

export const FormHeader = ({ title, description, headerBg, additionalText, compact = false }) => {
    const bg = `url(${headerBg})`;
    const styles = { color: "#eee", backgroundImage: bg, backgroundSize: "cover" };

    return (
        <header id="header" className={compact ? "compact" : ""} style={styles}>
            <div style={{ position: "absolute", right: 10, top: 10 }}>
                <User.Menu />
            </div>
            <h1 id="title" className="px-4 py-2">
                {title}
            </h1>
            <div className="extras mb-4 px-4 py-2 pb-4">
                <div id="description">
                    <i>{description}</i>
                </div>
                {additionalText && (
                    <div id="additionalText">
                        <small>{additionalText}</small>
                    </div>
                )}
            </div>
        </header>
    );
};
