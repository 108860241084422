import { Message } from "../components/Message";
import scream from "../assets/scream.png";
import { appTitle } from "../config";
import { CRLF, CRLF2 } from "../lib/utils";

// const MaintainerMailTo = ({ name, phone, email, company, error, title, description, version, form }) => {
//     return;
// };

export const FormSchemaError = ({ error, maintainer = {}, form: formMeta }) => {
    const { name, phone, company, email } = maintainer,
        { title, form, version } = formMeta;
    const msg = "report this to the form provider",
        subject = `${appTitle} Form Schema Interpretation Error`,
        formInfo = `${title} v${version} (${form})`,
        body = `Hi ${name},${CRLF2}The ${appTitle} app reported an error interpreting your form definition. I've sent you this error report to alert you to the problem. Please could you take a look? Thanks!${CRLF2}Error: ${error}${CRLF}Form: ${formInfo}${CRLF2}`;

    return (
        <Message
            title="Something went wrong"
            content={
                <>
                    <p>
                        The form couldn't be rendered for some reason. You can try again, but it's likely that the form
                        definition doesn't conform to Formulationist's specifications. Please{" "}
                        {email ? <a href={`mailto:${email}?subject=${subject}&body=${body}`}>{msg}</a> : msg}.
                    </p>
                    <small>
                        <b>Maintainer:</b> {name}
                        {company ? `, ${company}` : null}
                        {email ? ` (${email})` : null}
                        <br />
                        {phone ? (
                            <>
                                <b>Phone:</b> {phone}
                                <br />
                            </>
                        ) : null}
                        <b>Form:</b> {title} v{version} ({form})<br />
                        <b>Error detail:</b> {error.message}
                    </small>
                </>
            }
            severity="danger"
            image={scream}
        />
    );
};
