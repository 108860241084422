import useDocumentTitle from "@tanem/use-document-title";
import { appTitle, defaultForm } from "./config";
import Formulationist from "./Formulationist/";
import "./App.scss";
import { useLocation } from "wouter";
import { useEffect, useState } from "react";
import { recoverSavedState } from "./components/LoginWithSavedState";
import axios from "axios";
import { SERVER_CONFIG_KEY } from "./config";

axios.get("/api/settings").then(({ data }) => localStorage.setItem(SERVER_CONFIG_KEY, JSON.stringify(data)));

const FORM_QUERY_STRING_PARAMETER = "form";

const extractKeyFromQeryString = key => {
    const query = new URLSearchParams(window.location.search);
    if (query.has(key)) {
        const value = query.get(key);
        query.delete(key);
        const newQuery = query.toString();
        return [value, newQuery];
    } else return [undefined, query.toString()];
};

const getFormNameFromUrl = uri => uri.slice(1);

const App = () => {
    const [, setLoc] = useLocation();
    const [form, setForm] = useState();

    useEffect(() => {
        recoverSavedState();
    }, []);

    useEffect(() => {
        const formFromUrl = getFormNameFromUrl(window.location.pathname) || defaultForm;
        const [formFromQueryString = formFromUrl, newQuery] = extractKeyFromQeryString(FORM_QUERY_STRING_PARAMETER);
        setForm(formFromQueryString);
        setLoc(`/${formFromQueryString}${newQuery ? "?" + newQuery : ""}`);
        console.log("Resolved to form", formFromQueryString);
    }, [setLoc]);

    useDocumentTitle(`${appTitle}`);

    return form ? <Formulationist.DataProvider form={form} /> : null;
};

export default App;
