import { useUserInfo } from "@aaronpowell/react-static-web-apps-auth";
import { Dropdown } from "react-bootstrap";
import { FaUser } from "react-icons/fa";
import { loginWithSavedState } from "../../components/LoginWithSavedState";
import { useToggle } from "../../hooks/useToggle";
import { UserProfile } from "./UserProfile";

export const UserMenu = () => {
    const user = useUserInfo();
    const isLoggedIn = Boolean(user.identityProvider);
    const [show, setShow] = useToggle(false);

    return (
        <Dropdown id="userMenu">
            <Dropdown.Toggle variant="secondary" id="userMenuDropdown">
                <FaUser color={isLoggedIn ? "#fff" : "#bbb"} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {!user.identityProvider ? (
                    <Dropdown.Item onClick={() => loginWithSavedState()}>Log In</Dropdown.Item>
                ) : (
                    <>
                        <Dropdown.Item href={`/.auth/logout?post_logout_redirect_uri=${window.location.pathname}`}>
                            Log Out
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setShow(true)}>User Profile</Dropdown.Item>
                        {<UserProfile user={user} show={show} hide={() => setShow(false)} />}
                    </>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};
