import { Message } from "./Message";

const Banner = ({ spec }) => {
    const { title, message, severity, image, linkUrl, linkText } = spec;
    return (
        <Message
            title={title}
            content={message}
            linkUrl={linkUrl}
            linkText={linkText}
            severity={severity}
            image={image}
            compact={false}
            className="banner"
        />
    );
};

export default Banner;
