import { Spinner } from "react-bootstrap";
import { useFormSchema } from "../hooks/useFormSchema";
import FormulationistLegacy from "./";
import { HomeScreen } from "../components/HomeScreen";
import { WaityThing } from "../components/WaityThing";
import { useCallback, useEffect, useState } from "react";
import merge from "lodash/merge";
import {
    removeEmptyKeys,
    safeJsonParse,
    decodeBase64EncodedFormData,
    genUniqueId,
    getQueryParams,
    interpolateAll
} from "../lib/utils";
import { usePostForm } from "../hooks/usePostForm";
import { useUserInfo } from "@aaronpowell/react-static-web-apps-auth";
import { confirm } from "react-bootstrap-confirmation";
import LoginMenu from "../Page/Login";
import { Welcome } from "../components/Welcome";
import { AppContainer } from "../Page/AppContainer";
import { appTitle } from "../config";
import { Message } from "../components/Message";
import dotProp from "dot-prop";
import FormulationistCore from "../FCForm";

const loadFormDataFromLocalStorage = formId => safeJsonParse(localStorage.getItem(formId));
const saveFormDataToLocalStorage = (formId, data) => localStorage.setItem(formId, JSON.stringify(data));
const deleteFormDataFromLocalStorage = formId => localStorage.removeItem(formId);

const queryParams = getQueryParams();
const { data = "" } = queryParams;
const queryParamFormData = decodeBase64EncodedFormData(data);
const newCorrelationId = genUniqueId();
const startingData = { correlationId: newCorrelationId, ...queryParamFormData };
const editingPreviouslySubmittedFormData = startingData.correlationId !== newCorrelationId;
// console.log("form data from url query", startingData);

export const FormulationistDataProvider = ({ form }) => {
    const user = useUserInfo();
    const { formSchema = {}, lookupData = {}, gen, genTag, error, isFetching } = useFormSchema(form);
    const {
        response: {
            headers: {
                "x-permitted-providers": providers = "{}",
                "x-login-heading": loginHeading,
                "x-login-message": loginMessage,
                "x-form-title": title,
                "x-form-description": description,
                "x-form-version": version,
                "x-header-bg": headerBg,
                "x-error-title": errorTitle,
                "x-error-message": errorMessage
            } = {}
        } = {}
    } = error || {};

    useEffect(() => {
        console.log("form schema", formSchema, { title, gen, genTag });
    }, [formSchema, gen, genTag, title]);

    const { formMeta = {} } = formSchema;
    const {
        success = {},
        failure = {},
        endpoint = {},
        query = {},
        defaults = {},
        noPersist = false,
        remember = []
    } = (gen < 2 ? formSchema : formMeta.options) ?? {};
    const [formData, setFormData] = useState();
    const { isSubmitting, result: formPostResult, postFormData, editLink } = usePostForm(endpoint);

    useEffect(() => {
        if (!(isFetching || error)) {
            const savedDefaults = JSON.parse(localStorage.getItem(`${form}SavedDefaults`) || "[]");
            const rehydratedSavedDefaults = savedDefaults.reduce(
                (o, [dotPath, value]) => dotProp.set(o, dotPath, value),
                {}
            );
            console.log("saved defaults", rehydratedSavedDefaults);
            const { prepop: [, prepopulationData] = [] } = lookupData;
            const detokenisedDefaults = interpolateAll(defaults, { startingData });
            // console.log("Defaults", defaults, detokenisedDefaults);
            const mergeData = query.merge === undefined ? true : query.merge;
            const initialData = mergeData
                ? merge({}, startingData, detokenisedDefaults, prepopulationData)
                : merge({}, detokenisedDefaults, prepopulationData);

            const saved = noPersist ? {} : removeEmptyKeys(loadFormDataFromLocalStorage(form));
            const combinedFormData = editingPreviouslySubmittedFormData
                ? merge({}, rehydratedSavedDefaults, saved, initialData)
                : merge({}, initialData, rehydratedSavedDefaults, saved);
            // console.log("stored", saved, "intial", initialData, "combined", combinedFormData);

            setFormData(combinedFormData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form, isFetching, error, query.merge]);

    const saveRemembered = useCallback(
        formData => {
            const valuesToRemember = remember.map(dotPath => [dotPath, dotProp.get(formData, dotPath)]);
            localStorage.setItem(`${form}SavedDefaults`, JSON.stringify(valuesToRemember));
        },
        [form, remember]
    );

    const setFormDataAndSaveRememberedValues = useCallback(
        formData => {
            saveRemembered(formData);
            setFormData(formData);
        },
        [saveRemembered]
    );

    const storeFormData = useCallback(
        ({ formData }) => {
            if (!noPersist) saveFormDataToLocalStorage(form, formData);
            console.log("Saved.");
        },
        [form, noPersist]
    );

    const sendFormData = useCallback(
        ({ formData }) => {
            deleteFormDataFromLocalStorage(form);
            postFormData({ formData, queryParams, user: { ...user } });
        },
        [form, postFormData, user]
    );

    const resetFormData = useCallback(async () => {
        const affirmativeAnswer = await confirm("Do you really want to clear all form entries so far?", {
            title: "Reset Form",
            okText: "Yes, definitely. Delete it all.",
            okButtonStyle: "danger",
            cancelText: "No, please don't clear my form!"
        });

        if (affirmativeAnswer) {
            deleteFormDataFromLocalStorage(form);
            setFormData({});
        }
    }, [form]);

    const revertFormData = useCallback(async () => {
        const affirmativeAnswer = await confirm(
            "Do you really want to clear your entries and return the form to its initial state? (The form will reload)",
            {
                title: "Revert Form",
                okText: "Yes, definitely. Clear my entries.",
                okButtonStyle: "danger",
                cancelText: "No, please don't clear my form!"
            }
        );

        if (affirmativeAnswer) {
            deleteFormDataFromLocalStorage(form);
            setFormData({});
            window.location.reload();
        }
    }, [form]);

    const status = error?.response?.status,
        isAuthenticated = Boolean(user.identityProvider);

    return status === 403 ? (
        <AppContainer
            title={title || appTitle}
            description={description || "The easy, fast, clever form-filling app"}
            version={version}
            headerBg={headerBg}
        >
            {!(title || description) && <Welcome />}
            <Message title={errorTitle} content={errorMessage} style={{ fontSize: "larger" }} />
        </AppContainer>
    ) : status === 401 && !isAuthenticated ? (
        <AppContainer
            title={title || appTitle}
            description={description || "The easy, fast, clever form-filling app"}
            version={version}
            headerBg={headerBg}
        >
            {!(title || description) && <Welcome />}
            <LoginMenu providers={JSON.parse(providers)} mandatory heading={loginHeading} mesasge={loginMessage} />
        </AppContainer>
    ) : isFetching ? (
        <WaityThing />
    ) : error ? (
        <HomeScreen error={true} status={status} />
    ) : (
        <>
            {gen === 1 ? (
                <FormulationistLegacy.Core
                    form={form}
                    formData={formData}
                    lookupData={lookupData}
                    formSchema={formSchema}
                    queryParams={queryParams}
                    storeFormData={storeFormData}
                    sendFormData={sendFormData}
                    resetFormData={resetFormData}
                    revertFormData={revertFormData}
                    setFormData={setFormDataAndSaveRememberedValues}
                    formPostResult={formPostResult}
                    success={success}
                    failure={failure}
                    editLink={editLink}
                    editingForm={editingPreviouslySubmittedFormData}
                />
            ) : (
                <FormulationistCore
                    form={formSchema}
                    formId={form}
                    formData={formData}
                    lookupData={lookupData}
                    postData={sendFormData}
                    formPostResult={formPostResult}
                    success={success}
                    failure={failure}
                    editLink={editLink}
                />
            )}
            {isSubmitting && (
                <div>
                    Saving...&nbsp;
                    <Spinner animation="border" size="sm" />
                </div>
            )}
        </>
    );
};
