import { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { defaultBg } from "../config";
import { Footer } from "./Footer";
import { FormHeader } from "./FormHeader";

export const AppContainer = ({
    children,
    title = "",
    description = "",
    additionalText = "",
    headerBg,
    className,
    style = {},
    fullWidth = false
}) => {
    const [compact, setCompact] = useState(false);

    const hasScrolled = useCallback(() => setCompact(window.pageYOffset > 30), []);
    useEffect(() => {
        if (window) window.addEventListener("scroll", hasScrolled);
        return () => window.removeEventListener("scroll", hasScrolled);
    }, [hasScrolled]);

    const optionalExtraTopSpace = compact ? { paddingTop: "12em" } : {};
    const bg = headerBg
        ? `https://formulationist.blob.core.windows.net/forms/${headerBg}?sv=2019-12-12&st=2021-01-06T09%3A00%3A20Z&se=2036-01-07T09%3A00%3A00Z&sr=c&sp=r&sig=rTvMSklTAzo%2B7Pa%2BIfukk%2BoFzlYWa0ebIkikDpnNysg%3D`
        : defaultBg;

    return (
        <div style={style} id="app">
            <FormHeader
                compact={compact}
                title={title}
                description={description}
                additionalText={additionalText}
                headerBg={bg}
            />
            <Container id="main" className={className} style={optionalExtraTopSpace} fluid={fullWidth}>
                {children}
            </Container>
            <Footer headerBg={bg} />
        </div>
    );
};
