export const MS_PER_SECOND = 1000,
    SECONDS_PER_MINUTE = 60,
    MINUTES_PER_HOUR = 60,
    SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR,
    HOURS_PER_DAY = 24,
    MINUTES_PER_DAY = HOURS_PER_DAY * MINUTES_PER_HOUR,
    SECONDS_PER_DAY = MINUTES_PER_DAY * SECONDS_PER_MINUTE,
    DAYS_PER_WEEK = 7,
    DAYS_PER_FORTNIGHT = DAYS_PER_WEEK * 2,
    WEEKS_PER_MONTH = 4,
    WEEKS_PER_YEAR = 52,
    MS_PER_MINUTE = SECONDS_PER_MINUTE * MS_PER_SECOND,
    MS_PER_HOUR = SECONDS_PER_HOUR * MS_PER_SECOND,
    MS_PER_DAY = MINUTES_PER_DAY * MS_PER_MINUTE,
    MS_PER_WEEK = MINUTES_PER_DAY * MS_PER_MINUTE * DAYS_PER_WEEK,
    MS_PER_MONTH = MINUTES_PER_DAY * MS_PER_MINUTE * DAYS_PER_WEEK * WEEKS_PER_MONTH,
    MS_PER_YEAR = MINUTES_PER_DAY * MS_PER_MINUTE * DAYS_PER_WEEK * WEEKS_PER_YEAR;
