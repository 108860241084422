import { Alert, Image } from "react-bootstrap";

export const Message = ({
    title = "",
    content = "",
    linkUrl,
    linkText,
    severity = "info",
    image,
    compact = true,
    floating = false,
    className = "",
    style = {}
}) => (
    <Alert
        variant={severity}
        className={className + " " + (compact ? "mt-2" : "mt-4 mb-5")}
        style={{
            ...style,
            overflow: "auto",
            boxShadow: floating ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : null
        }}
    >
        {image && <Image src={image} align="right" style={{ maxWidth: 100, marginLeft: "1em" }} />}
        <Alert.Heading>{title}</Alert.Heading>
        {content}
        {linkUrl && <a href={linkUrl}>{linkText}</a>}
    </Alert>
);
