import { AppContainer } from "../Page/AppContainer";
import { Welcome } from "./Welcome";
import { appTitle } from "../config";
import { Message } from "./Message";
import scream from "../assets/scream.png";

const responses = {
    401: {
        title: "Not authorised",
        content:
            "You've not yet been granted permission to use this form. Contact your forms administrator to get access."
    },
    404: {
        title: "Form not found",
        content:
            "The form you're after couldn't be found. Contact your forms administrator for a working link to your form."
    },
    default: {
        title: "Something went wrong",
        content:
            "The form you wanted can't be loaded right now. There's nothing more we can do. You should ask your forms administrator what to do next."
    }
};
export const HomeScreen = ({ error = false, status = "default" }) => {
    const { title, content } = responses[status] || responses.default;
    return (
        <AppContainer title={appTitle} description="The easy, fast, clever form-filling app">
            <Welcome />
            {error && <Message title={title} content={content} severity="danger" image={scream} />}
        </AppContainer>
    );
};
