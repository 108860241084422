import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { UserInfoContextProvider } from "@aaronpowell/react-static-web-apps-auth";
import { Switch, Route } from "wouter";
import Pinger from "./components/Pinger";

const FormulationistApp = () => (
    <React.StrictMode>
        <UserInfoContextProvider>
            <Switch>
                <Route path="/ping" component={Pinger} />
                <Route>
                    <App />
                </Route>
            </Switch>
        </UserInfoContextProvider>
    </React.StrictMode>
);

ReactDOM.render(<FormulationistApp />, document.getElementById("root"));
