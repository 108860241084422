import { create, all } from "mathjs";
import { getYear, add, differenceInMilliseconds, formatDistance, parseISO, isValid } from "date-fns";
import {
    MS_PER_DAY,
    MS_PER_HOUR,
    MS_PER_MINUTE,
    MS_PER_MONTH,
    MS_PER_SECOND,
    MS_PER_WEEK,
    MS_PER_YEAR
} from "./time-constants";
import is from "@sindresorhus/is";

const ensurePlural = x => (x.endsWith("s") ? x : x + "s");

const msConversionFactors = {
    seconds: MS_PER_SECOND,
    minutes: MS_PER_MINUTE,
    hours: MS_PER_HOUR,
    days: MS_PER_DAY,
    weeks: MS_PER_WEEK,
    months: MS_PER_MONTH,
    years: MS_PER_YEAR
};
const functions = {
    year: date => getYear(date),
    date: (...args) => new Date(...args),
    dateadd: (date, number, unit) => {
        const start = parseISO(date),
            units = ensurePlural(unit);
        return isValid(start) && is.number(number) ? add(start, { [units]: number }) : undefined;
    },
    datestring: date => date.toLocaleDateString(),
    datediff: (date1, date2, units) =>
        differenceInMilliseconds(parseISO(date1), parseISO(date2)) / msConversionFactors[ensurePlural(units)],
    until: (date1, date2) => formatDistance(parseISO(date1), parseISO(date2)),
    today: () => new Date()
};

const Maths = () => {
    const cached = window.fappmath;
    if (cached) return cached;
    else {
        const math = create(all);
        math.import(functions);
        window.fappmath = math;
        return math;
    }
};

export default Maths;
