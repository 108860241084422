import React from "react";
import ObjectField from "@rjsf/core/lib/components/fields/ObjectField";
import { retrieveSchema } from "@rjsf/core/lib/utils";
import { Col } from "react-bootstrap";

// Code originally lifted from the old, neglected, unmaintained & abandoned https://github.com/audibene-labs/react-jsonschema-form-layout
// then made to work in 2021
export class LayoutField extends ObjectField {
    render() {
        const { uiSchema, errorSchema, idSchema, required, disabled, readonly, onFocus, onBlur, formData, registry } =
            this.props;
        const { definitions, fields, formContext } = registry;
        const { SchemaField, TitleField, DescriptionField } = fields;
        const schema = retrieveSchema(this.props.schema, definitions);
        const { title = "", description = "", properties = {} } = schema;

        const layoutSpecifications = uiSchema["ui:layout"];
        const flatLayout = layoutSpecifications.reduce((a, o) => a.concat(Object.keys(o)), []);
        const extraProperties = Object.keys(properties).filter(x => !flatLayout.includes(x));
        const extraLayout = extraProperties.map(x => ({ [x]: {} }));
        const layout = [...layoutSpecifications, ...extraLayout];

        return (
            <fieldset className="uiLayout">
                {title ? (
                    <TitleField
                        id={`${idSchema.$id}__title`}
                        title={title}
                        required={required}
                        formContext={formContext}
                    />
                ) : null}
                {description ? (
                    <DescriptionField
                        id={`${idSchema.$id}__description`}
                        description={description}
                        formContext={formContext}
                    />
                ) : null}
                {layout.map((row, index) => (
                    <div className="row" key={index}>
                        {Object.keys(row).map((name, index) => {
                            const { doShow, ...rowProps } = row[name];
                            const style = doShow && !doShow({ formData }) ? { display: "none" } : {};
                            if (properties[name]) {
                                return (
                                    <Col {...rowProps} key={index} style={style}>
                                        <SchemaField
                                            name={name}
                                            required={this.isRequired(name)}
                                            schema={properties[name]}
                                            uiSchema={uiSchema[name]}
                                            errorSchema={errorSchema[name]}
                                            idSchema={idSchema[name]}
                                            formData={formData[name]}
                                            onChange={this.onPropertyChange(name)}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            registry={registry}
                                            disabled={disabled}
                                            readonly={readonly}
                                        />
                                    </Col>
                                );
                            } else {
                                const { render, ...rowProps } = row[name];
                                const UIComponent = render ? render : () => null;

                                return (
                                    <Col {...rowProps} key={index} style={style}>
                                        <UIComponent
                                            name={name}
                                            formData={formData}
                                            errorSchema={errorSchema}
                                            uiSchema={uiSchema}
                                            schema={schema}
                                            registry={registry}
                                        />
                                    </Col>
                                );
                            }
                        })}
                    </div>
                ))}
            </fieldset>
        );
    }
}
