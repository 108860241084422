// This is here because there's a bug in the native widget that doesn't clear values when the value is undefined

import FormControl from "react-bootstrap/FormControl";
import InputGroup from "react-bootstrap/InputGroup";

export const TextareaWidget = ({
    id,
    placeholder,
    value,
    required,
    disabled,
    autofocus,
    label,
    readonly,
    onBlur,
    onFocus,
    onChange,
    options,
    schema,
    rawErrors = []
}) => {
    const _onChange = ({ target: { value } }) => onChange(value === "" ? options.emptyValue : value);
    const _onBlur = ({ target: { value } }) => onBlur(id, value);
    const _onFocus = ({ target: { value } }) => onFocus(id, value);

    return (
        <>
            <label htmlFor={id}>
                {label || schema.title}
                {required && (
                    <span aria-hidden className={rawErrors.length > 0 ? "text-danger ml-1" : "ml-1"}>
                        &thinsp;{"*"}
                    </span>
                )}
            </label>
            <InputGroup>
                <FormControl
                    id={id}
                    as="textarea"
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={readonly}
                    value={value || ""} // Fixes the problem
                    required={required}
                    autoFocus={autofocus}
                    rows={options.rows || 5}
                    onChange={_onChange}
                    onBlur={_onBlur}
                    onFocus={_onFocus}
                />
            </InputGroup>
        </>
    );
};
