import React, { useMemo, Fragment, useCallback } from "react";
import PropTypes from "prop-types";
import { Typeahead, Menu, MenuItem, Highlighter } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
import { getStringEntries, isObject, sortEntriesByValueLengthReverse, sortBy, groupBy } from "../../lib/utils";

const likeliestLabelKey = o => {
    const stringy = getStringEntries(o);
    const sorted = [...stringy].sort(sortEntriesByValueLengthReverse);
    return sorted[0][0];
};

const ID_KEY_SUFFIX = "id";
const likeliestIdKey = o => {
    const keys = Object.keys(o);
    const key = keys.includes(ID_KEY_SUFFIX) ? ID_KEY_SUFFIX : keys.find(x => x.toLowerCase().endsWith(ID_KEY_SUFFIX));
    return key;
};

export const TypeaheadWidget = props => {
    const {
        options,
        autofocus,
        disabled,
        id,
        label,
        onBlur,
        onChange,
        onFocus,
        placeholder,
        readonly,
        required,
        value,
        rawErrors,
        formContext
    } = props;

    const data = options.lookup
        ? (formContext.lookupData[options.lookup] || [])[1] || []
        : options.data
        ? options.data
        : [];

    const firstItem = data[0],
        itemIsObj = isObject(firstItem);
    const {
        idKey = itemIsObj ? likeliestIdKey(firstItem) : ID_KEY_SUFFIX,
        labelKey = itemIsObj ? likeliestLabelKey(firstItem) : "name",
        sort = false,
        groupByKey,
        favouritesKey,
        favourites = [],
        favouritesLabel
    } = options;
    const sortedData = useMemo(() => {
        const isGroupedWithFavourites = groupByKey && (favourites.length || favouritesKey);
        const isAFavourite = ({ [labelKey]: x }) => favourites.includes(x);
        const isNotAFavourite = x => !isAFavourite(x);
        const sorted = isGroupedWithFavourites
            ? [...data.filter(isAFavourite), ...sortBy(data.filter(isNotAFavourite), labelKey)]
            : sort
            ? sortBy(data, labelKey)
            : data;
        return sorted;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, favouritesKey, groupByKey, labelKey, sort]);
    const handleChanges = useCallback(ary => onChange(isObject(ary[0]) ? ary[0][idKey] : ary[0]), [idKey, onChange]);

    const renderGroupedList = useCallback(
        (results, menuProps, state) => {
            let index = 0;
            const groupedData = Object.entries(
                groupBy(results, groupByKey, labelKey, favouritesKey, favourites, favouritesLabel)
            );
            const items = groupedData.map(([group, rows]) => (
                <Fragment key={group}>
                    {index !== 0 && <Menu.Divider />}
                    <Menu.Header>{group}</Menu.Header>
                    {rows.map(row => {
                        const item = (
                            <MenuItem key={row[state.labelKey]} option={row} position={index}>
                                <Highlighter search={state.text}>{row[state.labelKey]}</Highlighter>
                            </MenuItem>
                        );

                        index += 1;
                        return item;
                    })}
                </Fragment>
            ));

            return <Menu {...menuProps}>{items}</Menu>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [favouritesKey, favouritesLabel, groupByKey, labelKey]
    );

    return (
        <Form.Group className="mb-0">
            <Form.Label className={rawErrors ? "text-danger" : ""}>
                {label}
                {required && "*"}
            </Form.Label>
            <Typeahead
                isInvalid={Boolean(rawErrors)}
                id={id}
                isLoading={false}
                options={sortedData}
                labelKey={labelKey}
                placeholder={placeholder}
                onChange={handleChanges}
                onBlur={onBlur && (event => onBlur(id, event.target.value))}
                onFocus={onFocus && (event => onFocus(id, event.target.value))}
                readonly={readonly}
                disabled={disabled}
                autoFocus={autofocus}
                selected={value ? [isObject(value) ? value[idKey] : value] : []}
                renderMenu={groupByKey ? renderGroupedList : undefined}
                inputProps={{ autoComplete: "nope" }} // because otherwise Chrome autocomplete cuts in. See discussion at https://gist.github.com/niksumeiko/360164708c3b326bd1c8
            />
        </Form.Group>
    );
};

TypeaheadWidget.propTypes = {
    options: PropTypes.shape({
        labelKey: PropTypes.string,
        idKey: PropTypes.string,
        groupByKey: PropTypes.string,
        data: PropTypes.array,
        dataUrl: PropTypes.string,
        sort: PropTypes.bool
    }),
    autofocus: PropTypes.bool,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func
};
