import { appTitle } from "../config";
import { CRLF2 } from "../lib/utils";
import { Message } from "./Message";

const subject = `Tell me about ${appTitle}`,
    body = `Please tell me all about ${appTitle}, your incredible forms app.`;

export const Welcome = () => (
    <Message
        floating
        severity="info"
        title={`Welcome to ${appTitle}`}
        className="p-5"
        content={
            <>
                <p>
                    Formulationist is an incredible form filling app that makes it easy for non-technical admins to
                    create forms that visitors will find easy &amp; pleasurable to fill.
                </p>
                <p>
                    <a href={`mailto:hello@jfdi.info?subject=${subject}&body=${body}${CRLF2}`}>
                        Drop us a line and we'll tell you all about it!
                    </a>
                </p>
            </>
        }
    />
);
