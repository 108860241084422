import { Formulationist } from "@jfdi/formulationist-core";
import { FormSinglePage } from "@jfdi/formulationist-core-form-single";
import { FormMultiPage } from "@jfdi/formulationist-core-form-multi";
import * as bs5 from "@jfdi/formulationist-widgets-bootstrap5";
import { Likert } from "@jfdi/formulationist-widgets-likert";
import { useEffect, useMemo, useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ErrorFallback";
import is from "@sindresorhus/is";
import { AppContainer } from "./Page/AppContainer";
import { isBanner } from "./lib/utils";
import Banner from "./components/Banner";
import { ReportResult } from "./Formulationist/ReportResult";

const Form = ({
    form: compiledForm,
    formId,
    formData,
    lookupData,
    postData,
    sendData,
    sendSerial,
    formPostResult,
    success,
    failure,
    editLink
}) => {
    const {
        formMeta: {
            options: {
                hideDirtyIndicator,
                headerBg,
                topBanner = {},
                bottomBanner = {},
                noEditLink = false,
                multipage = false,
                version = "unknown"
            } = {}
        } = {},
        formDef: { fields, title, description, maintainer = {} } = {}
    } = compiledForm;

    const { formDef: { serial } = {} } = compiledForm || {};
    const parameterData = useMemo(() => ({ ...(formData || {}) }), [formData]);
    const additionalWidgets = useMemo(() => ({ likert: Likert }), []);

    useEffect(() => {
        is.function(sendSerial) && sendSerial(serial);
    }, [sendSerial, serial]);

    const onChange = useCallback(
        ({ data, dict }) => {
            console.log("CHANGE!", { data, dict });
            is.function(sendData) && sendData({ data, dict });
        },
        [sendData]
    );

    const onSubmit = useCallback(
        formData => {
            console.log("DATA", formData);
            postData({ formData });
            alert(JSON.stringify(formData, null, 2));
        },
        [postData]
    );

    const onReset = useCallback(() => alert("Form reset/cancelled"), []);

    console.log("Compiled FC Form:", { compiledForm, parameterData });

    return is.nonEmptyObject(compiledForm) ? (
        <AppContainer
            title={title}
            description={description}
            headerBg={headerBg}
            additionalText={version && `Form version ${version}/fc`}
        >
            <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                    {isBanner(topBanner) && <Banner spec={topBanner} />}
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                        {formPostResult ? (
                            <ReportResult
                                result={formPostResult}
                                success={success}
                                failure={failure}
                                editLink={editLink}
                                noEditLink={noEditLink}
                            />
                        ) : (
                            <Formulationist
                                widgetPack={bs5}
                                additionalWidgets={additionalWidgets}
                                compiledForm={compiledForm}
                                formComponent={multipage ? FormMultiPage : FormSinglePage}
                                parameterData={parameterData}
                                key={formId} // aids runtime form-swap
                                formId={formId}
                                onChange={onChange}
                                onSubmit={onSubmit}
                                onReset={onReset}
                                lookups={lookupData}
                                debug={true}
                                footerComponent={isBanner(bottomBanner) ? () => <Banner spec={bottomBanner} /> : null}
                            />
                        )}
                    </ErrorBoundary>
                </div>
            </div>
        </AppContainer>
    ) : null;
};

export default Form;
