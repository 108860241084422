import { appTitle, version } from "../config";

const copyrightInceptionYear = 2021;
const copyrightYearRange = () => {
    const y = new Date().getFullYear();
    return y === copyrightInceptionYear ? `${y}` : `${copyrightInceptionYear}-${y}`;
};

export const Footer = ({ headerBg }) => {
    const styles = { backgroundImage: `url(${headerBg})` };
    return (
        <footer id="footer" style={styles}>
            <div className="left">
                <span className="hideOnMobile">Powered by&nbsp;</span>
                <b>{appTitle} </b>
                <span className="hideOnMobile">
                    {" "}
                    - <i>the easy form-filling app</i>
                </span>
            </div>
            <div className="center">
                <span className="hideOnMobile">Copyright&nbsp;</span>&copy;{" "}
                <span className="hideOnMobile">{copyrightYearRange()}&nbsp;</span>
                <a href="https://jfdi.info" target="_blank" rel="noreferrer">
                    JFDI <span className="hideOnMobile"> Consulting Ltd</span>
                </a>
            </div>
            <div className="right hideOnMobile">v{version}</div>
        </footer>
    );
};
