import { useEffect, useCallback, useRef } from "react";

// React hook for delaying calls with time
// returns callback to use for cancelling

export const useTimeout = (callback = () => null, timeoutMs = 0) => {
    const timeoutIdRef = useRef();
    const cancelTimeout = useCallback(() => {
        const timeoutId = timeoutIdRef.current;
        if (timeoutId) {
            timeoutIdRef.current = undefined;
            clearTimeout(timeoutId);
        }
    }, [timeoutIdRef]);

    useEffect(() => {
        timeoutIdRef.current = setTimeout(callback, timeoutMs);
        return cancelTimeout;
    }, [callback, timeoutMs, cancelTimeout]);

    return cancelTimeout;
};
