import axios from "axios";
import React, { useEffect, useState } from "react";

const Pinger = () => {
    const [done, setDone] = useState(false);

    useEffect(() => {
        axios
            .get("/api/ping")
            .then(() => setDone(true))
            .catch(e => null);
    }, []);

    return done ? <div>OK</div> : null;
};

export default Pinger;
