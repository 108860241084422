import { Form } from "react-bootstrap";
import BaseInput from "@rjsf/core/dist/es/components/widgets/BaseInput";
import DateInput from "@rjsf/core/dist/es/components/widgets/DateWidget";
import { attempt } from "@jfdi/attempt";
import { useEffect, useState } from "react";
import is from "@sindresorhus/is";
import Maths from "../../lib/expressions";

const math = Maths();

export const CalculatedWidget = props => {
    const {
        schema: { format },
        formContext: { formData },
        options: { formula, hidden = false },
        onChange
    } = props;
    const [value, setValue] = useState();

    useEffect(() => {
        const cleanedFormula = formula.startsWith("=") ? formula.split(1) : formula;
        const [error, result] = attempt(() => math.evaluate(cleanedFormula, formData));
        // console.log("Calc", result, error?.text);
        if (!(error || is.nan(result))) {
            const dispVal = is.date(result) ? result.toISOString().split("T")[0] : result;
            setValue(dispVal);
            onChange(dispVal);
        }
    }, [formData, formula, onChange]);

    return (
        <Form.Group className="mb-0" style={hidden ? { display: "none" } : undefined}>
            <Form.Label className={props.rawErrors ? "text-danger" : ""}>{props.label}</Form.Label>
            {format === "date" ? <DateInput {...props} value={value} /> : <BaseInput {...props} value={value} />}
        </Form.Group>
    );
};
