import { Button, Modal } from "react-bootstrap";
import userPic from "../../assets/user.jpg";

export const UserProfile = ({ show, hide, user }) => {
    console.log("User:", user.userId);
    const { picture, userId, userDetails, identityProvider, userRoles } = user;
    return (
        user && (
            <Modal show={show} onHide={hide}>
                <Modal.Header closeButton>
                    <Modal.Title>User Profile</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img src={picture || userPic} alt={userDetails} width={150} />
                    <h2>{userDetails}</h2>
                    <p>
                        {identityProvider}:{userId}
                    </p>
                    <p>Roles: {userRoles.join(", ")}</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={hide}>
                        Close
                    </Button>
                    {/* <Button variant="primary">Save changes</Button> */}
                </Modal.Footer>
            </Modal>
        )
    );
};
