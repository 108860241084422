import { attempt } from "@jfdi/attempt";
import is from "@sindresorhus/is";
import Maths from "./expressions";
import { Base64 } from "js-base64";

export * from "./time-constants";

export const getQueryParams = (url = document.location.href) => {
    const loc = new URL(url);
    return Object.fromEntries(loc.searchParams.entries());
};

export const isString = is.string;
export const isObject = is.object;
export const isEmptyObject = is.emptyObject;
export const isBetween = (x, a, b) => x >= a && x <= b;
export const isNull = is.null;
export const isNullOrUndefined = is.nullOrUndefined;
export const isNotNullOrUndefined = x => !is.nullOrUndefined(x);

export const safeJsonParse = (json, dflt = {}) => {
    const [, obj = dflt] = attempt(() => JSON.parse(json));
    const typesMatch =
        (Array.isArray(dflt) && Array.isArray(obj)) ||
        (isObject(dflt) && isObject(obj) && Object.keys(obj).length >= 0);
    return typesMatch ? obj : dflt;
};

export const decodeBase64EncodedFormData = data => {
    const [e, decoded = ""] = attempt(() => Base64.decode(data));
    e && console.error("Couldn't decode passed in form data. Incorrect format.");
    return safeJsonParse(decoded, {});
};

export const regexPercentBracketedToken = /%([^%]*)%/g;
export const regexDollarBraceToken = /\$\{([^}]*)\}/g;
export const interpolate = (templateString, params, regex = regexDollarBraceToken) =>
    templateString.replace(regexDollarBraceToken, (_, key) => params[key] || "?");
export const interpolateAll = (o, params = {}, regex = regexDollarBraceToken) => {
    const math = Maths();

    return !is.object(o)
        ? o
        : is.array(o)
        ? o.map(v => {
              const val = is.string(v) && v.startsWith("=") ? math.evaluate(v.slice(1), params) : v;
              const val2 = is.date(val) ? val.toISOString().split("T")[0] : val;
              //   console.log("Val", val2);
              return is.object(val2)
                  ? interpolateAll(val2, params, regex)
                  : is.string(val2)
                  ? interpolate(val2, params, regex)
                  : val2;
          })
        : Object.fromEntries(
              Object.entries(o).map(([k, v]) => {
                  const val = is.string(v) && v.startsWith("=") ? math.evaluate(v.slice(1), params) : v;
                  const val2 = is.date(val) ? val.toISOString().split("T")[0] : val;
                  //   console.log("Val", val2);
                  return is.object(val2)
                      ? [k, interpolateAll(val2, params, regex)]
                      : is.string(val2)
                      ? [k, interpolate(val2, params, regex)]
                      : [k, val2];
              })
          );
};

export const likelyLabelKeys = ["name", "description"];
export const entryIsString = ([, v]) => isString(v);
export const regexUrl = /((\w+:\/\/)[-a-zA-Z0-9:@;?&=/%+.*!'(),$_{}^~[\]`#|]+)/;
export const isUrl = x => x.match(regexUrl);
export const entryIsNotUrl = ([, v]) => !isUrl(v);
export const getStringEntries = (o = {}) => Object.entries(o).filter(entryIsString).filter(entryIsNotUrl);

export const removeEmptyKeys = o =>
    Object.fromEntries(
        Object.entries(o)
            .filter(([k, v]) => typeof v === "boolean" || Boolean(v))
            .map(([k, v]) => (isObject(v) ? [k, removeEmptyKeys(v)] : [k, v]))
    );

export const httpStatusIsOK = result => result && result.status && isBetween(parseInt(result.status), 200, 299);

export const makeSortFactors = (reverse = false) => {
    const factor = reverse ? -1 : 1;
    return [1 * factor, -1 * factor];
};
export const makeSimpleSortFn = (reverse = false) => {
    const [up, down] = makeSortFactors(reverse);
    return (a, b) => (a > b ? up : a < b ? down : 0);
};
export const makeSortFn = (reverse = false) => {
    const factor = reverse ? -1 : 1;
    return (a, b) => factor * a.localeCompare(b);
};

export const makeObjectSortFn = (key, reverse = false) => {
    const factor = reverse ? -1 : 1;
    return (a, b) => factor * a[key].localeCompare(b[key]);
};

export const sortEntriesByValueLength = ([, v1], [, v2]) =>
    v1.length > v2.length ? 1 : v1.length < v2.length ? -1 : 0;
export const sortEntriesByValueLengthReverse = (a, b) => -sortEntriesByValueLength(a, b);

export const sortBy = (ary, key) => {
    const sortFn = isObject(ary[0]) ? (a, b) => Intl.Collator().compare(a[key], b[key]) : Intl.Collator().compare;
    return [...ary].sort(sortFn);
};

export const groupBy = (
    ary,
    groupKey,
    sortKey,
    favouritesKey,
    favourites = [],
    favouritesGroupLabel = "Favourites"
) => {
    const sorted = sortBy(ary, sortKey);
    const favouritesGroup = [];
    const grouped = sorted.reduce((o, c) => {
        const key = c[groupKey];
        if (!o[key]) o[key] = [];
        o[key].push(c);

        if (
            ((favouritesKey && c[favouritesKey]) || favourites.includes(c[sortKey])) &&
            !favouritesGroup.includes(c[sortKey])
        ) {
            favouritesGroup.push(c);
        }

        return o;
    }, {});
    const sortedKeys = sortBy(Object.keys(grouped));
    const groups = sortedKeys.reduce((o, k) => {
        o[k] = grouped[k];
        return o;
    }, {});
    return favourites.length || favouritesKey ? { [favouritesGroupLabel]: favouritesGroup, ...groups } : groups;
};

export const debounce = (func = () => null, timeoutMs = 1000) => {
    let timeoutId;
    return function (...args) {
        const context = this;
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            timeoutId = null;
            func.apply(context, args);
        }, timeoutMs);
    };
};

export const genUniqueId = (n = 32) => [...Array(n)].map(i => (~~(Math.random() * 36)).toString(36)).join("");

export const CRLF = "%0D%0A";
export const CRLF2 = CRLF + CRLF;

export const nullFn = () => null;

export const deleteFormDataFromLocalStorage = formId => localStorage.removeItem(formId);

export const capFirst = s => s.charAt(0).toUpperCase() + s.slice(1);

export const classNames = a => a.join(" ");

export const isBanner = banner => banner && isObject(banner) && (banner.title || banner.message);
