import { useRef, useState } from "react";
import { interpolate } from "../lib/utils";
import { Base64 } from "js-base64";

const fieldIsNotAFile = ([, v]) => typeof v !== "string" || !v.startsWith("data:");
const filterOutDataFields = o => Object.fromEntries(Object.entries(o).filter(fieldIsNotAFile));
const base64EncodeObject = o => Base64.encode(JSON.stringify(o));

export const usePostForm = ({
    method = "POST",
    url: urlTemplate = "",
    headers = { "Content-Type": "application/json" }
}) => {
    const [result, setResult] = useState();
    const [isSubmitting, setSubmitting] = useState(false);
    const editLink = useRef();

    const postFormData = ({ formData, queryParams, user }) => {
        const base64FormData = base64EncodeObject(filterOutDataFields(formData));
        const payload = {
            ...formData,
            user,
            base64FormData
        };
        const url = interpolate(urlTemplate, queryParams),
            requestOptions = {
                method,
                headers,
                body: JSON.stringify(payload)
            };
        console.log("submitting", payload, "to", url);

        editLink.current = `${window.location.origin}${window.location.pathname}?data=${base64FormData}`;

        setSubmitting(true);
        fetch(url, requestOptions)
            .then(data => setResult(data))
            .catch(e => console.log(e))
            .finally(() => setSubmitting(false));
    };

    return { isSubmitting, result, postFormData, editLink: editLink.current };
};
