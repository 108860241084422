import axios from "axios";
import { useEffect, useState } from "react";
import { compile } from "@jfdi/formulationist-core-compiler";

const USE_API_COMPILE = true; // server-side compilation

const getFormSchema = formBlobName => {
    const blobUrl = `/api/GetForm?form=${formBlobName}`;
    console.log("Loading form", blobUrl);
    return axios.get(blobUrl, { headers: { "Access-Control-Expose-Headers": "error" } });
};

export const useFormSchema = formBlobName => {
    const [formDef, setFormDef] = useState({});
    const [error, setError] = useState();
    const [isFetching, setFetching] = useState(false);

    useEffect(() => {
        setFetching(true);
        getFormSchema(formBlobName)
            .then(({ data: body }) => {
                console.log("Form loaded", body);
                if (USE_API_COMPILE || body.gen === 1) {
                    setFormDef({ ...body });
                } else {
                    const { rawForm } = body;
                    const [errors, compiledForm] = compile(rawForm);
                    console.log("Client-side compilation:", { rawForm, compiledForm });
                    (errors ?? []).length && console.warn("Compilation Errors", errors);
                    setFormDef({ formSchema: compiledForm, lookupData: {}, gen: 2, genTag: "fc" });
                    setError((errors ?? [])[0]);
                }
            })
            .catch(setError)
            .finally(() => setFetching(false));
    }, [formBlobName]);

    return { isFetching, error, ...formDef };
};
