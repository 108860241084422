import { SERVER_CONFIG_KEY } from "../config";

const SAVED_STATE_KEY = "FormulationistSavedState";

const getDefaultProvider = () => {
    const { defaultAuthProvider } = JSON.parse(localStorage.getItem(SERVER_CONFIG_KEY));
    return defaultAuthProvider;
};

export const prepareLoginUrl = provider => {
    const redirectUrl = `${window.location.origin}${window.location.pathname}`;
    return `/.auth/login/${provider}?post_login_redirect_uri=${redirectUrl}`;
};

export const loginWithSavedState = (provider = getDefaultProvider()) => {
    localStorage.setItem(SAVED_STATE_KEY, window.location.search);
    window.location.assign(prepareLoginUrl(provider));
};

export const recoverSavedState = () => {
    const queryString = localStorage.getItem(SAVED_STATE_KEY);
    if (queryString) {
        window.location.assign(`${window.location.href}${queryString}`);
        localStorage.removeItem(SAVED_STATE_KEY);
    }
};
