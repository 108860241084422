import { useDelay } from "../hooks/useDelay";
import Loader from "react-loader-spinner";

const DEFAULT_DELAY_BEFORE_DISPLAY = 250,
    Sizes = { sm: 50, md: 75, lg: 100, xl: 150 },
    fullScreenOverlayStyle = {
        flexDirection: "column",
        backgroundColor: "rgba(0,0,0,0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    };

const Name = ({ rotation = 0, size }) => (
    <div
        style={{
            position: "absolute",
            textAlign: "center",
            verticalAlign: "middle",
            color: "#fff",
            fontWeight: "bold",
            margin: 0,
            transform: `rotate(${rotation}deg) translateY(-4em) ${rotation === 90 ? "translateX(2.75em)" : ""} ${
                rotation === 270 ? "translateX(-2.75em)" : ""
            }`
        }}
    >
        Formulationist
    </div>
);

export const WaityThing = ({ delayMs = DEFAULT_DELAY_BEFORE_DISPLAY, size = "lg" }) => {
    const delayPassed = useDelay(delayMs);

    return delayPassed ? (
        <div style={fullScreenOverlayStyle}>
            <Name size={size} />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Name size={size} rotation={270} />
                <div style={{ padding: "0.5em" }}>
                    <Loader type="Grid" color="#00BFFF" height={Sizes[size]} width={Sizes[size]} />
                </div>
                <Name size={size} rotation={90} />
            </div>
            <Name size={size} rotation={180} />
        </div>
    ) : null;
};

WaityThing.Sizes = Sizes;
