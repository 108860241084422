import { dfltSuccess, dfltFailure } from "../config";
import { Message } from "../components/Message";
import { httpStatusIsOK } from "../lib/utils";

const RedirectToTarget = ({ url, target = "", title }) =>
    target.includes("blank") ? (
        window.open(url, "_blank")
    ) : target.includes("self") ? (
        (document.location.href = url)
    ) : (
        <iframe className="mt-2" style={{ width: "100%", minHeight: "50vh" }} title={title} href={url} />
    );

const Links = ({ links = [] }) =>
    links.length > 0 && (
        <ul className="messageLinks">
            {links.map(({ title = "Link", url = window.location.href, target = "" } = {}) => (
                <li>
                    <button>
                        <a href={url} target={target}>
                            {title}
                        </a>
                    </button>
                </li>
            ))}
        </ul>
    );

export const ReportResult = ({ result, success = {}, failure = {}, editLink, noEditLink = false }) => {
    const isOk = httpStatusIsOK(result);
    const template = isOk ? dfltSuccess : dfltFailure;
    const {
        title = template.title,
        message = template.message,
        redirectUrl = template.redirectUrl,
        target = template.target,
        links = [],
        id = template.id
    } = isOk ? success : failure;
    return result ? (
        redirectUrl ? (
            <RedirectToTarget url={redirectUrl} target={target} title={id} />
        ) : (
            <Message
                severity={isOk ? "success" : "warning"}
                title={title}
                content={
                    <p>
                        {message}
                        {isOk && !noEditLink && (
                            <>
                                {" "}
                                You can edit this form by <a href={editLink}>saving and using this link</a>.
                            </>
                        )}
                        <Links links={links} />
                    </p>
                }
            />
        )
    ) : null;
};
