import { appTitle } from "../config";
import is from "@sindresorhus/is";
import { loginWithSavedState } from "../components/LoginWithSavedState";
import { Button } from "react-bootstrap";
import { FaApple, FaFacebook, FaGithub, FaGoogle, FaKey, FaMicrosoft, FaTwitter } from "react-icons/fa";

const providerLabels = {
        aad: { label: "Microsoft Personal & Work Accounts", icon: FaMicrosoft },
        apple: { label: "Apple ID", icon: FaApple },
        facebook: { label: "Facebook Account", icon: FaFacebook },
        github: { label: "GitHub Account", icon: FaGithub },
        google: { label: "Google Account", icon: FaGoogle },
        twitter: { label: "Twitter Account", icon: FaTwitter }
    },
    signInLabel = "Sign in with ";

const Icon = ({ icon: Icon }) => <Icon size="2em" style={{ marginRight: "1em" }} />;

const Login = ({ provider }) => {
    const { label, icon = FaKey } = (is.object(provider) ? provider : providerLabels[provider]) || {};

    return (
        <li>
            <Button onClick={() => loginWithSavedState(provider)}>
                <Icon icon={icon} />
                {signInLabel}
                {label}
            </Button>
        </li>
    );
};

const LoginMenu = ({ providers, mandatory = false, heading, message }) => {
    if (providers.length === 0) {
        console.log("LOGINMENU Logging in with default provider");
        loginWithSavedState();
    } else {
        if (providers.length === 1) {
            console.log("LOGINMENU Logging in with specified provider");
            loginWithSavedState(providers[0]);
        } else {
            console.log("LOGINMENU Showing login menu");
            return (
                <section className="login-form">
                    <h2>{heading || `Login to ${appTitle}`}</h2>
                    {mandatory && (
                        <p>
                            {message ||
                                "The form you're trying to use requires that you sign in using one of the services listed below."}
                        </p>
                    )}
                    <ul>
                        {providers.map(p => (
                            <Login provider={p} />
                        ))}
                    </ul>
                </section>
            );
        }
    }
};

export default LoginMenu;
