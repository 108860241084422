import { useCallback, useState } from "react";
import debounce from "lodash/debounce";

export const useDelayedAction = (actionFn, timeoutMs = 1000) => {
    const [pending, setPending] = useState();

    const actionFnWithAfters = useCallback(
        (...args) => {
            const retVal = actionFn(...args);
            // console.log("After");
            setPending(false);
            return retVal;
        },
        [actionFn]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedFn = useCallback(debounce(actionFnWithAfters, timeoutMs), [actionFn, timeoutMs]);

    const delayedFnWithNotify = useCallback(
        (...args) => {
            // console.log("Before");
            setPending(true);
            return delayedFn(...args);
        },
        [delayedFn]
    );

    const reset = () => {
        delayedFn.cancel();
        setPending(undefined);
    };

    return [pending, delayedFnWithNotify, reset];
};
